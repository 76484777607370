export default {
  index: {
    lang: 'hk',

    menu1: '首頁',
    menu2: '遊戲產品',
    menu3: '關於我們',
    menu4: '企業生活',

    tips: '遊戲健康忠告：抵制不良遊戲，拒絕盜版遊戲。注意自我保護，小心受騙上當。適度遊戲益腦，沉迷遊戲傷身。合理安排時間，享受健康生活。',
    number1: '京公网安备11010502054233',
    number2: '京ICP备2023035689号-1',
    copyright: '索洛幻想 版權所有 Copyright @ 2021-2025 Solotopia™, All Rights Reserved.',

    title1: '遊戲產品',

    title2: '關於我們',
    info21: '創造出',
    info22: '讓玩家快樂、讓自己自豪的遊戲',
    info23: '索洛幻想專注於全球化精品手遊，並不斷探索創新領域，業務遍及超140個國家地區，開拓及深耕歐洲、北美、日韓、中東、東南亞等市場。',
    info24: '企業文化',
    info25: '熱愛',
    info26: '簡單',
    info27: '正念',
    info28: '極端',
    info29: '謙卑',

    title3: '企業生活',
    info31: '辦公環境',
    info32: '索洛幻想在北京、濟南均設有辦公室，簡潔明亮的室內環境，給員工更好的工作體驗。',
    info33: '歡樂活動',
    info34: '生日慶祝、節日活動、興趣俱樂部，豐富多彩的公司活動，一起度過快樂時光！',
    info35: '員工福利',
    info36: '補充商業保險、福利假期、企業優惠等貼心到位的福利項目，健康工作、享受生活。',
  },

  Mergeland: {
    text1: '以經典童話故事《愛麗絲奇境歷險記》和《愛麗絲穿鏡奇幻記》為原型改編的遊戲劇情，再現了神秘仙境的經典場景和人物。',
    text12: '在這裡，你可以跟隨小女孩Ally的腳步，再次穿越探訪奇妙仙境，拯救白兔、瘋帽子等人物，恢復仙境的原本面貌。',
    text2: '遊戲亮點',
    text3: '充滿神奇魔力的童話世界觀，從清新的原野、神秘的森林，到宏偉壯觀的皇宮，在我們藝術家的設計下，一個個經典的童話場景展現在玩家眼前，無處不在的精美細節帶來前所未有的視覺盛宴。',
    text4: '合併玩法和場景修復玩法的融合，讓玩家合併出的每個元素都可作為修復仙境的素材，隨著元素等級的提高，整個破損的仙境也逐漸恢復原貌展現給玩家。',
    text5: '豐富多元的活動和節慶副本令玩家不僅可以體驗《灰姑娘》、《睡美人》等經典童話，還能通過完成活動挑戰獲取角色/修復物的皮膚，按照個人喜好裝扮屬於自己的獨特仙境。',
  },

  Secret: {
    text1: 'Taylor為了尋找消失的母親，開始了一段起伏的冒險，甜蜜的戀愛、神秘的黑貓傳說、埋藏於Loveland20多年的秘密等待揭曉...在這座充滿秘密和陰謀的小島上，幫助Taylor揭開母親消失的真相吧！',
    text2: '遊戲亮點',
    text3: '簡單易學且有趣的融合遊戲，多元化的物品收集體驗。',
    text4: '隨著劇情的推進和資源的收集，玩家可以選擇不同的建築風格，從頭開始設計和建造整個島嶼。 也可以通過參加限時活動，取得獨特的場景裝飾物品。',
    text5: '不間斷的賽事玩法，豐富的活動獎勵，讓你有更爽快的遊戲體驗。',
  },

  Mergecove: {
    text1: '在都市中失意的艾莉回到家鄉，卻沒想到昔日那仙境般美麗的小鎮已被災難摧毀。 與祖母愛麗絲一起重振小鎮吧，揭開小鎮的秘密，讓她恢復過去的美麗！ 踏入這裡，一同開始嶄新的旅程！',
    text2: '遊戲亮點',
    text3: '時尚現代化的遊戲場景，讓玩家透過合成元素享受修復小鎮過程的同時，還可以切換獲取人物和場景皮膚，裝扮屬於自己的特色小鎮。',
    text4: '玩家還能透過活動獲得和收集的收藏卡，跟隨主角一起了解主角的祖母年輕時穿越仙境的奇妙故事。',
  },

  Bubble: {
    text1: '享受最輕鬆有趣的泡泡拍攝遊戲，上千個引人入勝的關卡和令人驚嘆的泡泡圖形，匹配、射擊和爆破氣泡，沉浸在一個充滿五彩泡泡等待被擊落的繽紛世界。',
    text2: '遊戲亮點',
    text3: '超多謎題關卡，豐富的玩法和活動獎勵供玩家探索發現，體驗更多玩法樂趣。',
    text4: '色彩鮮豔的泡泡和多樣的增/減益元素，享受豐富的視覺效果同時，還可以拯救被泡泡困住的可愛動物。',
  },

  Sudoku: {
    text1: '一款令人上癮的數位益智遊戲，不僅可以隨時隨地享受數獨遊戲，還能從中學習數獨技巧。',
    text2: '遊戲亮點',
    text3: '與世界各地的玩家同場競技，提升段位挑戰更高難度的數獨遊戲。',
    text4: '豐富的主題活動、獨創的IQ遊戲，讓玩家在收集各種活動明信片的同時，體驗更多樣化的大腦訓練主題。',
  },

}