export default {
  index: {
    lang: 'en',

    menu1: 'HOME',
    menu2: 'GAMES',
    menu3: 'ABOUT US',
    menu4: 'LIFE AT SOLOTOPIA',

    tips: '',
    number1: '',
    number2: '',
    copyright: 'Copyright @ 2021-2025 Solotopia™, All Rights Reserved.',

    title1: '游戏产品',

    title2: '关于我们',
    info21: 'To Create Games That',
    info22: 'Delight Players and Make Yourself Proud',
    info23: 'Solotopia focuses on carving out quality mobile games that eye global markets while gearing toward exploring the realms that inspire innovation. Our business layout spreads through over 140 countries and regions, among which Europe, North America, Japan, South Korea, MENA and SEA are deemed the major markets we will cultivate.',
    info24: 'Our Values',
    info25: 'Keep Passion',
    info26: 'Stay Simple',
    info27: 'Do Right',
    info28: 'Push Beyond',
    info29: 'Be Humble',

    title3: '企业生活',
    info31: 'Offices',
    info32: 'We set up our offices in Beijing and Jinan, providing employees with a better work experience.',
    info33: 'Recreation',
    info34: 'We organize multiple staff engagement activities tailored to different festivals and individual hobbies, making Solotopia a lovely home for gaming enthusiasts.',
    info35: 'Benefits',
    info36: 'We offer a full-fledged array of benefits like supplementary insurance, paid time off, etc., to ensure each member of Solotopia can be inspired by maintaining a healthier work-life balance.',
  },

  Mergeland: {
    text1: 'Adapted from the novel "Alice\'s Adventures in Wonderland" and the fantasy film "Alice Through the Looking Glass", this game brings these widely-known scenes and characters of Wonderland under the spotlight.',
    text12: 'You can follow the footsteps of Ally to revisit the mysterious land, trying to save White Rabbit and Mad Hatter while bringing Wonderland back to life.',
    text2: 'Highlights',
    text3: 'The array of classical scenes with exquisite design brings players an immersive adventure experience, leading them into the mysterious Wonderland where the wilderness, forests and palaces also deliver the power of magic.',
    text4: 'The integration of Merge and Repair gameplays enables players to accumulate the necessary elements to restore Wonderland. As the levels of elements upgrade, the once-broken Wonderland will be restored to its former glory.',
    text5: 'Thanks to the variety of activities and holiday dungeons, players can immerse themselves in fairy tales like "Cinderella" and "Sleeping Beauty" while decorating their exclusive Wonderland by acquiring the skins of characters and restorations from the in-game challenges.',
  },

  Secret: {
    text1: "To find her missing mother, Taylor embarked on an up-and-down journey where the sweet love story, mysterious legend about the black cat and all secrets deeply buried under Loveland awaited to unfold. Let's help Tylor unveil the truth about her mom's disappearance on this island, which is full of conspiracies.",
    text2: 'Highlights',
    text3: 'An easy-to-learn merge game with diversified gameplays of item collection.',
    text4: 'As the plot and resource collection progress, players can choose different architectural styles to design and construct the whole island from scratch. You can also gain unique decorations by attending time-limited events.',
    text5: 'The unremitting update of gameplays and rich rewards bring you a much more jolly experience.',
  },

  Mergecove: {
    text1: "Ellie, the girl encountering frustration in the city, didn't expect her once beautiful hometown to have been destroyed by catastrophe as she returned. Come and join Grandma Alice to help revitalize the town by unfolding the secrets behind it!",
    text2: 'Highlights',
    text3: 'This game with stylish scenes encourages players to decorate the town as they please while repairing it by merging elements.',
    text4: "By collecting virtual cards via in-game activities, you can learn more about the fantasy story of Grandma Alice's adventure in Wonderland when she was young.",
  },

  Bubble: {
    text1: 'A bubble shooter game with thousands of eye-grabbing levels and stunning bubble elements. You will undoubtedly be indulged in the colorful bubble world through the gameplays of Match, Shoot and Burst!',
    text2: 'Highlights',
    text3: 'The wealth of puzzle levels, gameplays and rewards within the game offer players space to explore more fun and joy.',
    text4: 'You can rescue these cute animals trapped in bubbles while enjoying the visually vivid vibes created by colorful bubbles and various buff/debuff elements.',
  },

  Sudoku: {
    text1: 'A time-killer puzzle game where players can enjoy the satisfaction that Sudoku brings while learning the tricks to play.',
    text2: 'Highlights',
    text3: 'In Sudoku, you can compete with players worldwide and challenge more difficulty levels as your rank elevates.',
    text4: 'The diversified theme activities and original IQ quizzes encourage players to experience multiple brain-training gameplays while collecting virtual postcards.',
  },

}